"use client"
import { ShowRoom, extractData, getHomepageHomePage } from "@/api"
import { ContactWidget } from "@/components/shared/ContactWidget"
import theme from "@/theme/theme"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { FormContact } from "./Form"

const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px;
  width: 100%;
  background-color: rgba(245, 248, 251, 1);
  ${theme.breakpoints.down("sm")} {
    padding: 0px;
  }
`

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 881px;
  padding: 32px 48px 48px;
  gap: 48px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  ${theme.breakpoints.up("md")} {
    width: 881px;
    border-radius: 20px;
  }
`

const SectionContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const ContactRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
`

export const Contact = () => {
  const [agencyOptions, setAgencyOptions] = useState<ShowRoom[]>([])

  useEffect(() => {
    const getAgencyData = async () => {
      const homeData = await getHomepageHomePage().then(extractData)
      if (homeData) {
        setAgencyOptions(homeData.showRooms)
      }
    }
    getAgencyData()
  }, [])
  return (
    <ContactWrapper>
      <ContactContainer>
        <SectionContact>
          <Typography variant="h2" mt={2} fontSize={32} fontWeight={300}>
            במה נוכל לעזור
          </Typography>
          <Typography mt={2} fontSize={18} fontWeight={300}>
            הנציגים שלנו ישמחו למצוא את הרכב המושלם בשבילך
          </Typography>
        </SectionContact>
        <ContactRow>
          {agencyOptions.map((agency) => {
            return (
              <ContactWidget
                key={agency.id}
                address={agency.name}
                phone={agency.phone}
              />
            )
          })}
        </ContactRow>

        <FormContact agencyOptions={agencyOptions} />
      </ContactContainer>
    </ContactWrapper>
  )
}
